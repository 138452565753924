<template>
  <div>
    <filters
      :add="true"
      :search="true"
      @filtered-items="filter"
      @add-button-clicked="addClass"
    />
    <b-card>
      <b-table
        hover
        :fields="fields"
        :items="GET_CLASS_LIST.results"
        show-empty
        :empty-text="$t('general.no_data_to_view')"
        style-class="slots_text_align vgt-table bordered"
        @row-clicked="rowClick"
      >
        <template #head()="data">
          {{ $t(data.label) }}
        </template>
        <template
          #cell(actions)="data"
        >
          <div class="d-flex align-items-center no-wrap">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="btn-icon mr-50 p-25"
              size="sm"
              @click="$router.push(`/class/class-info/${data.item.id}`)"
            >
              <feather-icon
                size="22"
                icon="ChevronsRightIcon"
              />
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="warning"
              class="btn-icon mr-50 p-25"
              size="sm"
              @click="$router.push(`/class/class-predmets/${data.item.id}`)"
            >
              <feather-icon
                size="22"
                icon="EditIcon"
              />
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              class="btn-icon p-25"
              size="sm"
              @click="deleteClass(data.item.id)"
            >
              <feather-icon
                size="22"
                icon="Trash2Icon"
              />
            </b-button>
          </div>
        </template>

      </b-table>

      <!-- pagination -->
      <!-- slot-scope="props" -->
      <template>
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">{{ $t('general.show_from') }}</span>
            <b-form-select
              v-model="params.page_size"
              :options="pageOptions"
              class="mx-1"
              @change="changePerPage"
            />
            <span class="text-nowrap"> {{ $t('general.show_to') }} </span>
            <!-- из {{ props.total }} -->
          </div>
          <div>
            <b-pagination
              v-model="params.page"
              :total-rows="GET_CLASS_LIST.count"
              :per-page="params.page_size"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @change="changePage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </b-card>
    <add-edit-class />
  </div>
</template>

<script>
import {
  BTable,
  BFormSelect,
  BPagination,
  BButton,
  BCard,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters } from 'vuex'
import filters from '@/components/filters.vue'
import areYouSure from '@/mixins/areYouSure'
import toast from '@/mixins/toast'
import AddEditClass from './components/classInfoModals/AddEditClass.vue'

export default {
  components: {
    BCard,
    BTable,
    BFormSelect,
    BPagination,
    BButton,
    filters,
    AddEditClass,
  },
  directives: {
    Ripple,
  },
  mixins: [areYouSure, toast],
  data() {
    return {
      pageOptions: [3, 5, 10],
      params: {
        page_size: 10,
        page: 1,
        ordering: null,
        search: null,
      },
      fields: [
        {
          label: 'ID',
          key: 'id',
        },
        {
          label: 'classes.class_title',
          key: 'title',
        },
        {
          label: 'classes.capacity',
          key: 'capacity',
        },
        // {
        //   label: 'Дата начала доступа',
        //   key: 'start_time',
        // },
        // {
        //   label: 'Дата окончания доступа',
        //   key: 'end_time',
        // },
        {
          label: 'general.actions',
          key: 'actions',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('classes', ['GET_CLASS_LIST']),
  },
  mounted() {
    this.FETCH_CLASS_LIST(this.params)
  },
  methods: {
    ...mapActions('classes', ['FETCH_CLASS_LIST', 'DELETE_CLASS', 'UPDATE_CLASS']),
    addClass() {
      this.$bvModal.show('AddEditClassModal')
    },
    deleteClass(id) {
      this.$_showAreYouSureModal().then(value => {
        if (value) {
          this.UPDATE_CLASS({ id, is_active: false })
            .then(() => {
              this.$_successToast(this.$t('general.removed'))
              this.params.page = 1
              this.FETCH_CLASS_LIST(this.params)
            })
            .catch(() => {
              this.$_errorToast(this.$t('general.error'))
            })
        }
      })
    },
    rowClick(data) {
      this.$router.push(`/class/class-detail/${data.id}`)
    },
    // for pagination and filters
    changePerPage(page_size) {
      this.params.page_size = page_size
      this.params.page = 1
      this.FETCH_CLASS_LIST(this.params)
    },
    changePage(value) {
      this.params.page = value
      this.FETCH_CLASS_LIST(this.params)
    },
    filter(filteredData) {
      this.params = { ...this.params, ...filteredData }
      this.FETCH_CLASS_LIST(this.params)
    },
  },
}
</script>

<style>

</style>
